b,
strong {
  font-weight: $weight-bold;
}

a {
  // color: $font-color;
  transition: $transition-base;
  &:hover,
  &:focus,
  &:active {
    //color: darken($primary-color, 5%);
    // color: $primary-color;
    text-decoration: none;
  }
}

h1 {
  line-height: 1;
}

h2,
h3,
h4,
h5 {
  margin: 0.5em 0 0.3em;
  line-height: 1.2;
}

h1 {
  font-weight: $weight-bold;
  color: $color-organge2;
  font-family: $family-header;
}

h2 {
  font-weight: $weight-bold;
  color: $color-organge2;
  font-family: $family-header;
}

h3 {
  font-weight: $weight-bold;
  color: $color-organge2;
  font-family: $family-header;
}

h4 {
  letter-spacing: 1px;
  //font-weight: $weight-medium;
}

h5 {
  //font-weight: $weight-thin;
}

// size
h1 {
  font-size: rem(48, $font-size-mobile);
}

h2 {
  font-size: rem(41.6, $font-size-mobile);
}

h3 {
  font-size: rem(36.8, $font-size-mobile);
}

h4 {
  font-size: rem(19.2, $font-size-mobile);
}

h5 {
  font-size: rem(14, $font-size-mobile);
}
h6 {
  font-size: rem(12, $font-size-mobile);
}

@include media-breakpoint-up(lg) {
  h1 {
    font-size: rem(90, $font-size-desktop);
  }

  h2 {
    font-size: rem(70, $font-size-desktop);
  }

  h3 {
    font-size: rem(55, $font-size-desktop);
  }

  h4 {
    font-size: rem(26, $font-size-desktop);
  }

  h5 {
    font-size: rem(16, $font-size-desktop);
  }
  h6 {
    font-size: rem(12, $font-size-desktop);
  }
}

// html editor
.text-tiny {
  font-size: 0.75rem;
}

.text-big {
  font-size: 1.5rem;
}
