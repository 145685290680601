@import "./mixins";
@import "./variables";

// plugins
@import "./bootstrap/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import "~react-modal-video/scss/modal-video.scss";

//@import './fonts';
@import "./base";
@import "./sections";
@import "./form";

// top
//@import './layout/hamburger';
//@import './layout/top';
@import "./layout/top-navbar";

// bottom
@import "./layout/bottomPanel";
@import "./layout/bottomMenu";

@import "./cms/cms-site";

// modules
@import "./modules/breadcrumb";
@import "./modules/rotatingBanner";
@import "./modules/bannerSize";
@import "./modules/pageTiles";
//@import './modules/blogTiles';
@import "./modules/slider";
@import "./modules/multiColumnPanel";

// components
@import "../components";

@import "./site/index";

//aos
@import "~aos/src/sass/aos";

@media print {
  @import "./print";
}

@import "./youtubeNew.scss";

//banner-text-bar
section.banner-text-bar {
  .htmlPanel {
    p {
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  label.choose-file-label {
    background-color: white;
    margin-bottom: 0px !important;
    margin-right: 0.7rem;
    padding: 0.5rem 1rem;
    font-weight: 600;
    border-radius: 7px;
    font-size: 16px;
  }

  div.file__attach__text {
    margin-left: 1rem;
    color: #f9582c;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.pageTiles__bsyc .pageTile.tile {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  height: inherit;

  .tile__middle__bg__mobile {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;

    &.tileType__1 {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &.index__odd {
    .tile__middle__bg {
      position: absolute;
      //left: 49.9%;
      //transform: translateX(-50%);
      //width: 18.58%;
      right: 1px;
      width: 38%;
      transform: translateX(50%);
      z-index: 2;
      top: 0;
      display: none;

      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    .tile__middle__bg__2 {
      position: absolute;
      //left: 49.9%;
      //transform: translateX(-50%);
      //width: 18.58%;
      //right: -9%;
      width: 38%;
      left: -38%;
      transform: translateX(50%);
      z-index: 2;
      top: -2px;
      display: none;

      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    .tile__bg__container {
      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          order: 1;
          z-index: -1;
        }
      }
    }
  }

  &.index__even {
    .tile__middle__bg {
      position: absolute;
      left: 1px;
      transform: translateX(-50%);
      //width: 18.58%;
      width: 38%;
      z-index: 4;
      display: none;
      top: -2px;

      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    .tile__middle__bg__2 {
      position: absolute;
      //left: 49.9%;
      //transform: translateX(-50%);
      //width: 18.58%;
      //right: -9%;
      width: 38%;
      right: 0;
      //left: -38%;
      transform: translateX(50%);
      z-index: 2;
      top: -2px;
      display: none;

      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }

    .tile__bg__container {
      &.tileType__1 {
        @include media-breakpoint-up(lg) {
          z-index: -1;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    //flex: 0 0 100%;
    //max-width: 100%;
  }
}

.pageTiles__bsyc .tile__bg__container {
  padding-top: 60%;
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  //transition: all 0.2s;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      padding-top: 35%;
      flex: 1 0 50%;
      max-width: 50%;
      overflow: unset;
    }
  }
}

.pageTiles__bsyc .index__odd .tile__bg {
  padding-top: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      left: -17%;
      width: 117.8%;
      //left: -17%;
      //width: 117.8%;
    }
  }
}

.pageTiles__bsyc .index__even .tile__bg {
  padding-top: unset;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      right: -18%;
      width: 117.8%;
      z-index: 1;
    }
  }
}

.pageTiles__bsyc.pageTiles.tiles.tileType__2 {
  .pageTile.tile {
    flex: 1 0 100% !important;
    max-width: 100% !important;

    @include media-breakpoint-up(md) {
      flex: 1 0 50% !important;
      max-width: 50% !important;
    }

    @include media-breakpoint-up(xl) {
      flex: 1 0 33.3% !important;
      max-width: 33.3% !important;
    }
  }
}

.pageTiles__bsyc.pageTiles.tiles.tileType__2.useSlider .pageTile.tile {
  flex: 1 0 100% !important;
  max-width: 100% !important;
}

.htmlPanel a {
  //text-decoration: underline;
  //font-size: 16px;
}

.top__bar__dropdown .top__bar__sub__container {
  transition: opacity 0.3s ease;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.top__bar__dropdown:hover .top__bar__sub__container {
  opacity: 1;
  transition: opacity 0.3s ease;
  height: auto;
  width: 100%;
  position: absolute;
  left: 0;
}
.quote__panel__btn span {
  font-family: "acumin-pro-extra-condensed", serif;
  font-size: 1.6rem;
  letter-spacing: 0.07rem;
  line-height: 1.2;
  margin: 0 0.5rem;
  @media screen and (min-width: 576px) {
    font-size: 1.8rem;
    margin: 0 1rem;
  }
}
.top__bar__sub__container__wrap {
  background: white;
  margin-top: 0.5rem;
}
// .home-banner.bannerPanel .slick-slider {
.bannerPanel .slick-slider {
  @media screen and (min-width: 576px) {
    padding-top: 6rem;
  }
  @media screen and (min-width: 1200px) {
    padding-top: 1rem;
  }
  @media screen and (min-width: 1600px) {
    padding-top: 6rem;
  }
}
.banner_image_container {
  @media screen and (min-width: 576px) {
    padding-top: 6rem;
  }
  @media screen and (min-width: 1200px) {
    padding-top: 1rem;
  }
  @media screen and (min-width: 1600px) {
    padding-top: 6rem;
  }
}
#map-container {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
#map-container iframe {
  width: 100%;
  height: 100%;
}
#footer-panel {
  // padding-left: 0;
  // padding-right: 0;
}
#footer-panel .container {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
#footer-panel .container .row {
  margin-left: 0;
  margin-right: 0;
}
#footer-panel .container .row .col-sm-6.col-12 {
  padding-left: 0;
  padding-right: 0;
}
.contact-wrapper label {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -10px;
}
#introduction-text .contact-wrapper label {
  color: black !important;
}
#contact-us span a {
  color: white !important;
}
.contact-wrapper .helptext label {
  margin-bottom: 1rem;
}
.youtube-text-panel iframe {
  width: 100vw;
  @media screen and (max-width: 576px) {
    height: 56.25vw;
  }
}

.map {
  @media screen and (min-width: 576px) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
  }
}
.map iframe {
  @media screen and (min-width: 576px) {
    height: 80%;
  }
}
