.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 576px) {
    margin: 2rem 0;
  }

  @include media-breakpoint-up(md) {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  @include media-breakpoint-up(xl) {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  @media (min-width: 1300px){
    margin-left: -2rem;
    margin-right: -2rem;
  }

  &.useSlider {
    display: block;

    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;

    // .pageTile_details {
    //   transition: $transition-base;
    //   background-image: url("/assets/eco-care-tile.svg");
    //   background-repeat: no-repeat;
    //   background-size: 140%;
    //   background-position-y: center;
    //   background-color: $color-dark-gray;
    // }
    // .pageTile__title,
    // .pageTile_desc {
    //   color: white;
    //   transition: $transition-base;
    // }
    .tile__bg__container{
      //transform: scale(1.02);
    }
    .tile__bg {
      //transform: scale(1.02);
      //transition: $transition-base;
    }

    .tile__bg__inner{
      transform: scale(1.02);
    }

    .pageTile_btn {
      background-color: #BC3E20;
      color: white;
    }

  }
}

.pageTile__title {
  font-family: $family-header;
  font-size: 2.4rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0 0 1.5rem 0;
  color: white;

  &.tileType__2{
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.8rem;
    }
  }

  &.tileType__1 {
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.5rem;
    }
  }

  //flex: 1 0 50%;
  //max-width: 50%;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 1rem;
  color: white;
  margin: 0 0 1.5rem 0;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  &.tileType__1 {
    @include media-breakpoint-up(sm) {
      font-size: 0.8rem;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    @include media-breakpoint-up(lg) {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      font-size: 18px;
    }

    @include media-breakpoint-up(xl) {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      font-size: 18px;
    }

    @media (min-width: 1300px){
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      overflow: unset;
      text-overflow: unset;
      display: block;
      font-size: 18px;
    }

  }
}

.tile__details__container {
  flex: 1 0 100%;
  max-width: 100%;
  position: relative;
  //background-color: $color-gray2;
  margin-top: -1px;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      flex: 1 0 50%;
      max-width: 50%;
      margin-top: 0px;
    }
  }
}

.index__odd .pageTile_details {
  position: relative;
  overflow: hidden;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  text-align: left;
  //border-bottom: 5px solid $color-organge;
  color: white;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #434343;
  //top: 0;
  //left: 0;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      // width: 90%;
      // position: absolute;
      // padding: 2rem 1.5rem 1.8rem 2rem;
      width: 81%;
      position: relative;
      padding: 2rem 1.5rem 2rem 2rem;
    }
  }

}

.index__even .pageTile_details {
  position: relative;
  overflow: hidden;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  text-align: left;
  //border-bottom: 5px solid $color-organge;
  color: white;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: #434343;
  //top: 0;
  //left: 0;

  &.tileType__1 {
    @include media-breakpoint-up(lg) {
      // position: absolute;
      // padding: 2rem 1.5rem 1.8rem 7rem;

      width: 81%;
      float: right;
      position: relative;
      padding: 2rem 2rem 2rem 1.5rem;

    }
  }
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left,
        rgba(#fff, 0.3),
        rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}

.pageTile_btn {
  display: inline-block;
  font-size: 0.8rem;
  background-color: $color-organge;
  color: white;
  font-weight: $weight-bold;
  border-radius: 7px;
  padding: 0.4rem 1rem;
}