// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova", sans-serif;
$family-header: "acumin-pro-extra-condensed", serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-normal: $weight-light;
$weight-semi-bold: 600;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #f95427;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-organge: #f95427;
$color-organge2: #f95427;
$color-dark-orange: #bc3e20;
$color-dark-gray: #212427;
$color-gray: #525252;
$color-gray2: #434343;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;

// site colors
$primary-color: $color-organge;
$font-color: $color-gray2;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: #f95427;
$link-hover-color: #bc3e20;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  mobile: 44px,
  small-device: 106px,
  tablet: 106px,
  desktop: 106px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
