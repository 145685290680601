/*
  <div class="tiles">
    <div class="tile">
      <div class="tile__content>
        <div class="tile__bg></div>
        <div class="tile__details></div>
      </div>
    </div>
  </div>
*/

@mixin tile--titleDesc(
  $breakpoints: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
  ),
  $margin: 4rem,
  $padding: 1.4rem,
  $ratio: 70%
) {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .tile {
    padding: $padding/2;
    // height: 100%;
    height: inherit;
    @each $bp, $number in $breakpoints {
      @include media-breakpoint-up(#{$bp}) {
        flex: 0 0 100% / $number;
        max-width: 100% / $number;
      }
    }
  }

  .tile__content {
    display: flex;
    flex-direction: row;
    //justify-content: space-between;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    //box-shadow: 0 0 10px #999;
    flex-wrap: wrap
  }

  .tile__bg {
    @include bg-img();
    @include img-ratio($ratio);
    overflow: hidden;
    .tile__bg__inner{
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      transition: all 0.2s;
      transform: scale(1.01);
    }
    //transition: all 0.2s;
    //transform: scale(1.01);
  }
}