.main-content {
  // padding-top: map-get($top-bar-height, mobile);
  // @include media-breakpoint-up(lg) {
  //   padding-top: map-get($top-bar-height, desktop);
  //   //padding-top: 86px;  //86px, 56px
  // }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem; 
    color: white;
    background: $primary-color;
    border: 1px solid $primary-color;
    padding: 0.5rem 1rem;
    font-weight: $weight-semi-bold;
    border-radius: 7px;
    font-size: 16px;
	&:hover{
      background-color: white;
      border-color: $primary-color;
      color: $primary-color;
    }
  }
}
